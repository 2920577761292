import React, {Component, useEffect, useState} from 'react'
import {BrowserRouter, HashRouter, Redirect, Route, Switch} from 'react-router-dom'
import './scss/style.scss'
import {useDispatch, useSelector} from "react-redux";
import {serviceUser} from "./services/user.service";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token)

  useEffect( () => {
    syncUser()
  }, [token])

  async function syncUser() {
    try {
      let localUser = JSON.parse(localStorage.getItem("user"))
      let userData = await serviceUser()
      if (localUser.selectedOrganization == null && userData.organizations.length > 0) {
        userData.selectedOrganization = userData.organizations?.[0]
      } else if (userData.organizations.length > 0) {
        let localSelectedOrganization = localUser.selectedOrganization
        let userOrganizations = userData.organizations
        let userSelectedOrganization = userOrganizations.find(o => o.id === localSelectedOrganization.id )
        if (userSelectedOrganization != null) {
          userData.selectedOrganization = userSelectedOrganization
        }
      }
      console.log("Organizations", userData)
      dispatch({ type: 'set', user: userData })
      localStorage.setItem("user", JSON.stringify(userData))
    } catch (e) {
      console.error(e)
    }
  }

  return (
    // <div>{showUser ? "true" : "false"}
    // </div>
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
        {
          token == null && <Redirect to="/login" />
        }
      </React.Suspense>
    </BrowserRouter>
  )

}

export default App
