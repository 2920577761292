import axios from "axios";
import {isSuperAdmin} from "../utilities/utilities";

export const API_URL = "https://api.happymaintenance.tablab.srl/api/v1";
// export const API_URL = "http://localhost:23146/api/v1";

export const axiosPublic = axios.create()

export const axiosAuthenticated = axios.create()
axiosAuthenticated.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  const user = JSON.parse(localStorage.getItem("user"))

  if (user.selectedOrganization != null) {
    config.params = { organizationId : user.selectedOrganization.id }
  }

  // if (isSuperAdmin(user) && user.selectedOrganization != null) {
  //   config.params = { organizationId : user.selectedOrganization.id }
  // }
  return config;
})

export const axiosBaseAuthenticated = axios.create()
axiosBaseAuthenticated.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  return config;
})

export const axiosOrganizations = axios.create()
axiosOrganizations.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  return config;
})
