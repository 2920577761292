import {API_URL, axiosAuthenticated, axiosOrganizations, axiosPublic} from "./service";
import {user} from "../utilities/utilities";

export async function serviceUserOrganizations() {
  try {
    const response = await axiosAuthenticated.get(API_URL + "/user/organizations")
    console.log(response)
    return response.data.organizations
  } catch (e) {
    return null
  }
}

export async function serviceOrganizations() {
  const response = await axiosOrganizations.get(API_URL + "/organizations")
  console.log(response)
  return response.data
}

export async function serviceCreateOrganization(name, description, file) {
  const url = API_URL + "/organizations"
  const formData = new FormData();
  formData.append(`name`, name);
  formData.append(`description`, description)

  if (file != null) {
    formData.append("image", file);
  }

  const response = await axiosOrganizations.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export async function serviceUpdateOrganization(id, name, description, file) {
  const url = `${API_URL}/organizations/${id}`
  const formData = new FormData();

  if (name != null) {
    formData.append(`name`, name);
  }

  if (description != null) {
    formData.append(`description`, description);
  }

  if (file != null) {
    formData.append("image", file);
  }

  const response = await axiosOrganizations.patch(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}


