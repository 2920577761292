
export function user() {
  return JSON.parse(localStorage.getItem("user"))
}

export function isSuperAdmin(user) {
  return user?.role === "superAdmin"
}

export function isReader(user) {
  return user?.role === "reader"
}
export function isAdmin(user) {
  return user?.role === "admin" || user?.role === "superAdmin"
}

export function isNotAdmin(user) {
  return user?.role !== "admin" && user?.role !== "superAdmin"
}

export function isNotReader(user) {
  return user?.role !== "reader"
}

export function isRoleSuperAdmin(role) {
  return role === "superAdmin"
}

export function isRoleMaintainer(role) {
  return role === "maintainer"
}


export function isApp() {
  return window.location.hostname.includes("app.")
}

export function isAppAndMaintainer(user) {
  return isApp() && isRoleMaintainer(user?.role)
}
