import {API_URL, axiosAuthenticated, axiosBaseAuthenticated, axiosOrganizations, axiosPublic} from "./service";
import {isSuperAdmin, user} from "../utilities/utilities";
import {serviceUserOrganizations} from "./organizations.service";

export async function serviceLogin(username, password) {
  const response = await axiosPublic.post(API_URL + "/users/auth/login", {
    username: username,
    password: password,
  })
  const user = response.data
  user.token = response.headers.authorization
  localStorage.setItem("token", user.token)
  localStorage.setItem("user", JSON.stringify(user))
  user.organizations = await serviceUserOrganizations()
  console.log(response.headers)
  return user
}

export async function serviceUsers() {
  const response = await axiosAuthenticated.get(API_URL + "/users")
  return response.data
}

export async function serviceSuperUsers() {
  const response = await axiosBaseAuthenticated.get(API_URL + "/users")
  return response.data.filter(user => isSuperAdmin(user))
}

export async function serviceAllUsers() {
  const response = await axiosBaseAuthenticated.get(API_URL + "/users")
  return response.data
}

export async function serviceUser() {
  const response = await axiosAuthenticated.get(API_URL + "/users/profile/me")
  const user = response.data
  user.organizations = await serviceUserOrganizations()
  return response.data
}

export async function serviceUsersRoles() {
  const response = await axiosAuthenticated.get(API_URL + "/users/roles/list")
  console.log(response)
  return response.data.roles
}

export async function serviceCreateUser(email, password, name, surname, role, imageFile, selectedOrganizationsIds) {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  formData.append('name', name);
  formData.append('surname', surname);
  formData.append('role', role);

  const loggedUser = user()
  if (selectedOrganizationsIds != null && isSuperAdmin(loggedUser)) {
    formData.append('organizationIds', JSON.stringify(selectedOrganizationsIds))
  } else {
    formData.append('organizationIds', JSON.stringify([loggedUser.selectedOrganization.id]))
  }

  if (imageFile != null) {
    formData.append("image", imageFile);
  }

  const response = await axiosBaseAuthenticated.post(API_URL + "/users", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export async function serviceUpdateUser(userId, name, surname, role, imageFile, selectedOrganizationsIds) {
  const formData = new FormData();

  if (name != null) {
    formData.append('name', name);
  }

  if (surname != null) {
    formData.append('surname', surname);
  }

  if (role != null) {
    formData.append('role', role);
  }

  if (selectedOrganizationsIds != null && isSuperAdmin(user())) {
    formData.append('organizationIds', JSON.stringify(selectedOrganizationsIds))
  }

  if (imageFile != null) {
    formData.append("image", imageFile);
  }

  const response = await axiosBaseAuthenticated.patch(API_URL + "/users/" + userId, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export async function serviceDeleteUser(userId) {
  const response = await axiosAuthenticated.delete(API_URL + "/users/" + userId)
  return response.data
}

export async function serviceUpdateUserPassword(userId, password) {
  const response = await axiosAuthenticated.patch(API_URL + "/users/" + userId + "/password", {
    password: password
  })
  return response.data
}


